import { Draft, PayloadAction, createSlice, isAnyOf } from "@reduxjs/toolkit";
import { attend, removeAttend } from "@xcira/commons";
import { RootState } from "../app/store";

export type SaleViewSize = "large" | "medium" | "small" | "compact";

type AccessToken = string;

type DialogType = "media" | null;

type TDialog<T extends DialogType, DialogMetadata> = {
  type: T;
} & (DialogMetadata extends undefined
  ? {
      metadata?: undefined;
    }
  : {
      metadata: DialogMetadata;
    });

type MediaDialog = TDialog<"media", { lotId: string; selectedIndex: number }>;
type NoDialog = TDialog<null, Record<never, never>>;

type Dialog = MediaDialog | NoDialog;

type DialogState = {
  dialogOpen: boolean;
} & Dialog;

type DialogMetadata<T extends DialogType> = Extract<Dialog, { type: T }>["metadata"];

type SingleSaleView = {
  saleViewSize: SaleViewSize;
  isLotNotesOpen: boolean;
  onBlockExpandedMedium: boolean;
  onBlockExpandedLarge: boolean;
  showWatchedLots: boolean;
  showLookAhead: boolean;
  selectedLotId: string | null;
} & DialogState;

const initialState: SingleSaleView = {
  saleViewSize: "medium",
  isLotNotesOpen: false,
  onBlockExpandedMedium: false,
  onBlockExpandedLarge: true,
  showWatchedLots: false,
  showLookAhead: false,
  selectedLotId: null,
  dialogOpen: false,
  type: null,
  metadata: {},
};

const singleSaleViewSlice = createSlice({
  name: "singleSaleView",
  initialState,
  reducers: {
    setSaleViewSize: (
      state,
      action: PayloadAction<{ accessToken: AccessToken; size: SaleViewSize }>
    ) => {
      state.saleViewSize = action.payload.size;
    },
    setIsLotNotesOpen(state, action: PayloadAction<{ accessToken: AccessToken; isOpen: boolean }>) {
      state.isLotNotesOpen = action.payload.isOpen;
    },
    setOnBlockExpandedMedium(
      state,
      action: PayloadAction<{ accessToken: AccessToken; expanded: boolean }>
    ) {
      state.onBlockExpandedMedium = action.payload.expanded;
    },
    setOnBlockExpandedLarge(
      state,
      action: PayloadAction<{ accessToken: AccessToken; expanded: boolean }>
    ) {
      state.onBlockExpandedLarge = action.payload.expanded;
    },
    setShowWatchedLots(
      state,
      action: PayloadAction<{ accessToken: AccessToken; showWatchedLots: boolean }>
    ) {
      state.showWatchedLots = action.payload.showWatchedLots;
    },
    setSelectedlotId(
      state,
      action: PayloadAction<{ accessToken: AccessToken; lotId: string | null }>
    ) {
      state.selectedLotId = action.payload.lotId;
    },
    setShowLookAhead(
      state,
      action: PayloadAction<{ accessToken: AccessToken; showLookAhead: boolean }>
    ) {
      state.showLookAhead = action.payload.showLookAhead;
    },
    openDialog: (
      state: Draft<DialogState>,
      action: PayloadAction<{ accessToken: AccessToken } & Dialog>
    ) => {
      state.dialogOpen = true;
      state.type = action.payload.type;
      state.metadata = action.payload.metadata;
    },
    closeDialog: (
      state: Draft<DialogState>,
      // accessToken must be passed so that the reducer can find the correct slice
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      _action: PayloadAction<{ accessToken: AccessToken }>
    ) => {
      state.dialogOpen = false;
    },
  },
});

type SubState = Parameters<typeof singleSaleViewSlice.reducer>[0];

const initialSaleViewState: Record<AccessToken, SubState> = {};

const saleViewSlice = createSlice({
  name: "saleView",
  initialState: initialSaleViewState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(attend, (state, action) => {
        const { accessToken } = action.payload;
        state[accessToken] = singleSaleViewSlice.reducer(state[accessToken], action);
      })
      .addCase(removeAttend, (state, action) => {
        const accessToken = action.payload;
        delete state[accessToken];
      })
      .addMatcher(
        isAnyOf(
          singleSaleViewSlice.actions.setSaleViewSize,
          singleSaleViewSlice.actions.setIsLotNotesOpen,
          singleSaleViewSlice.actions.setOnBlockExpandedMedium,
          singleSaleViewSlice.actions.setOnBlockExpandedLarge,
          singleSaleViewSlice.actions.setShowWatchedLots,
          singleSaleViewSlice.actions.setSelectedlotId,
          singleSaleViewSlice.actions.setShowLookAhead,
          singleSaleViewSlice.actions.openDialog,
          singleSaleViewSlice.actions.closeDialog
        ),
        (state, action) => {
          const accessToken =
            typeof action.payload === "string" ? action.payload : action.payload.accessToken;
          state[accessToken] = singleSaleViewSlice.reducer(state[accessToken], action);
        }
      );
  },
});

export const {
  setSaleViewSize,
  setIsLotNotesOpen,
  setOnBlockExpandedMedium,
  setOnBlockExpandedLarge,
  setShowWatchedLots,
  setSelectedlotId,
  setShowLookAhead,
  openDialog,
  closeDialog,
} = singleSaleViewSlice.actions;

export const { name: saleViewSlicePath, reducer: saleViewReducer } = saleViewSlice;

export const selectSaleViewSize = (state: RootState, accessToken: AccessToken): SaleViewSize =>
  state?.saleView[accessToken]?.saleViewSize ?? "medium";

export const selectIsLotNotesOpen = (state: RootState, accessToken: AccessToken): boolean =>
  state?.saleView[accessToken]?.isLotNotesOpen ?? false;

export const selectHasLotNotes = (state: RootState, accessToken: AccessToken): boolean =>
  state?.saleView[accessToken]?.isLotNotesOpen ?? false;

export const selectOnBlockExpandedMedium = (state: RootState, accessToken: AccessToken): boolean =>
  state?.saleView[accessToken]?.onBlockExpandedMedium ?? false;

export const selectOnBlockExpandedLarge = (state: RootState, accessToken: AccessToken): boolean =>
  state?.saleView[accessToken]?.onBlockExpandedLarge ?? false;

export const selectShowWatchedLots = (state: RootState, accessToken: AccessToken): boolean =>
  state?.saleView[accessToken]?.showWatchedLots ?? false;

export const selectShowLookAhead = (state: RootState, accessToken: AccessToken): boolean =>
  state?.saleView[accessToken]?.showLookAhead ?? false;

export const selectSelectedLotId = (state: RootState, accessToken: AccessToken): string | null =>
  state?.saleView[accessToken]?.selectedLotId ?? null;

export const selectDialogOpen = (state: RootState, accessToken: AccessToken): boolean =>
  state?.saleView[accessToken]?.dialogOpen ?? false;

export const selectDialogType = (state: RootState, accessToken: AccessToken): DialogType =>
  state?.saleView[accessToken]?.type ?? null;

export const selectDialogMetadata = <T extends DialogType>(
  state: RootState,
  accessToken: AccessToken
): DialogMetadata<T> => state.saleView[accessToken]?.metadata as DialogMetadata<T>;
